var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !!_vm.videoCode
    ? _c("v-flex", { ref: "parent", class: _vm.youtubecontainer }, [
        _c("iframe", {
          ref: "iframe",
          attrs: {
            allowfullscreen: _vm.allowfullscreen,
            frameborder: _vm.frameborder,
            src: _vm.url,
            id: "youtubeIframe",
            allow:
              "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          },
          on: {
            load: function($event) {
              return _vm.$emit("load")
            }
          }
        })
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }