<template>
  <v-flex v-if="!!videoCode" :class="youtubecontainer" ref="parent">
    <iframe
      :allowfullscreen="allowfullscreen"
      :frameborder="frameborder"
      :src="url"
      @load="$emit('load')"
      ref="iframe"
      id="youtubeIframe"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    />
  </v-flex>
</template>

<script>
import { EventBus } from "@/Eventbus"
import { mapActions, mapGetters, mapState } from "vuex"

export default {
  name: "YouTubeContainer",
  data() {
    return {
      player: null,
      parent: null,
      iframe: null,
      aspectRatio: 0,
      url: `https://www.youtube.com/embed/${this.videoCode}?enablejsapi=1&rel=0&start=${this.startTime}`,
      target: null
    }
  },
  computed: {
    ...mapState("media", ["youtubePlayerInstance"]),
    ...mapGetters("auth", ["isHost"]),
    currentMission() {
      return this.$store.getters.getCurrentMission
    },
    user() {
      return this.$store.getters.user
    },
    youtubecontainer() {
      if (this.isHost || this.currentMission.behavior == "URL") {
        return "host-container"
      } else {
        return "player-container"
      }
    }
  },
  mounted() {
    this.iframe = this.$refs.iframe
    // eslint-disable-next-line no-undef
    this.player = new YT.Player("youtubeIframe", {
      events: {
        onReady: this.onPlayerReady.bind(this),
        onStateChange: this.onPlayerStateChange.bind(this)
      }
    })

    window.addEventListener("resize", this.onResize)
    const height = this.iframe.clientHeight
    const width = this.iframe.clientWidth
    this.aspectRatio = height / width
    this.$nextTick(this.update)
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize)
    EventBus.$off("playYoutubeVideo", this.onPlay)
    EventBus.$off("pauseYoutubeVideo", this.onPause)
  },
  methods: {
    ...mapActions("media", ["updateYoutubePLayerInstance"]),
    onResize() {
      this.update()
    },
    update() {
      const parentWidth = this.$parent.$el.clientWidth
      const parentHeight = this.$parent.$el.clientHeight

      let newHeight = parentWidth * this.aspectRatio
      if (newHeight > parentHeight) newHeight = parentHeight
      const newWidth = newHeight / this.aspectRatio

      this.iframe.height = newHeight
      this.iframe.width = newWidth
    },
    onPlayerStateChange(playerStatus) {
      if (playerStatus.data == -1) {
        // unstarted
        //
      } else if (playerStatus.data == 0) {
        // ended
        //
      } else if (playerStatus.data == 1) {
        // playing
        this.onPlayerPlay(this.player)
      } else if (playerStatus.data == 2) {
        // paused
        this.onPlayerPaused(this.player)
      } else if (playerStatus.data == 3) {
        this.onPlayerPaused(this.player)
        //
      } else if (playerStatus.data == 5) {
        // video cued
        //
      }
    },
    onPlay(data) {
      if (this.target) this.target.seekTo(data.seekTime)
      if (this.target) this.target.playVideo()
    },
    onPause() {
      if (this.target) this.target.pauseVideo()
    }
  },
  props: {
    startTime: {
      type: Number,
      default: 0
    },
    frameborder: {
      type: Number,
      default: 0
    },
    allowfullscreen: {
      type: Boolean,
      default: true
    },
    videoCode: {
      type: String,
      required: true
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    onPlayerReady: {
      type: Function,
      default: function (event) {
        if (event) {
          this.target = event.target

          if (this.autoplay) {
            this.target.playVideo()
          } else {
            this.target.pauseVideo() // pause the initial load of the video
          }
        }
        EventBus.$on("playYoutubeVideo", this.onPlay)
        EventBus.$on("pauseYoutubeVideo", this.onPause)
      }
    },
    onPlayerPaused: {
      type: Function,
      default: () => {}
    },
    onPlayerPlay: {
      type: Function,
      default: () => {}
    }
  }
}
</script>

<style>
.host-container {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  display: flex;
}
.player-container {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  position: relative;
}
.player-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* .player-container iframe {
  pointer-events: none;
} */
.player-container iframe {
  position: absolute;
  top: -60px;
  left: 0;
  width: 100%;
  height: calc(100% + 120px);
}
.video-foreground {
  pointer-events: none;
}
</style>
